import { QAQC_CHART_TYPES } from 'state-domains/constants';
import { formatDateToDisplay } from 'src/utilities/formatters';

import { ReportsData } from '../types';

export const formatChartData = (type: QAQC_CHART_TYPES, data: ReportsData[]) => {
    if (type === QAQC_CHART_TYPES.Z_SCORE || type === QAQC_CHART_TYPES.STANDARD_CONTROL) {
        return formatZScoreData(data);
    }
    return data;
};

const formatZScoreData = (data: ReportsData[]) =>
    data.map((x) => ({
        ...x,
        certificateCompleted: formatDateToDisplay(x.certificateCompleted),
        crm1sd: parseFloat(x.crm1sd.numberDecimal),
        crmCertifiedValue: parseFloat(x.crmCertifiedValue.numberDecimal),
        zScore: parseFloat(x.zScore.numberDecimal),
    }));
